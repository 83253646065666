import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Formik, Form } from 'formik';
import axios from 'axios';
import BounceLoader from 'react-spinners/BounceLoader';

import styles from './create.module.scss';

const Disable = ({ alias, token }) => {
  const [result, setResult] = useState(null);

  const mapError = (e) => ({
    email: e.forwards && e.forwards[0] && e.forwards[0][0].join(','),
  });

  return (
    <div className={`${styles.container} d-flex flex-fill`}>
      <div
        className="container text-white d-flex flex-row align-items-center justify-content-center"
        id="create"
      >
        <Formik
          initialValues={{}}
          onSubmit={(values, actions) => {
            axios
              .post(`${process.env.API_ENDPOINT}disablealias`, {
                token,
              })
              .then((r) => {
                actions.setSubmitting(false);
                setResult(r.data);
              })
              .catch((error) => {
                actions.setSubmitting(false);
                actions.setErrors(mapError(error.response.data));
              });
          }}
          render={({ errors, status, touched, isSubmitting, values }) => (
            <>
              {result && !isSubmitting && (
                <div className="col-md-9 text-center">
                  <h1 className="mb-5">The alias has been disabled.</h1>
                  <div className="my-5">Thank you for using LastMX!</div>
                  <div>
                    <Link to="/" className="btn btn-outline-primary">
                      Create a new address
                    </Link>
                  </div>
                </div>
              )}
              {isSubmitting && (
                <div className={styles.loading}>
                  <BounceLoader size={20} color={'#fff'} />
                </div>
              )}
              {!result && !isSubmitting && (
                <div className="col-md-9 text-center">
                  <h1 className="mb-5">
                    Are you sure you want to permanently disable{' '}
                    <em>{alias}</em>?
                  </h1>
                  <div className="mb-5">
                    Once disabled, you will stop receiving emails sent at this
                    address.
                    <br />
                    It cannot be reactivated later.
                  </div>
                  <Form>
                    <div className="form-row align-items-center justify-content-center">
                      <div className="col-md-8">
                        <button
                          className="btn btn-danger text-white text-uppercase"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Kill it!
                        </button>
                        {errors.email && touched.email && (
                          <div className={styles.error}>{errors.email}</div>
                        )}
                        {status && status.msg && <div>{status.msg}</div>}
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
};

export default Disable;

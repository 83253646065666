import React from 'react';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { LogOnMount } from '@amplitude/react-amplitude';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Disable from '../components/disable';

const DisablePage = ({ location }) => {
  // Don't render this page server side
  if (typeof window === 'undefined') {
    return null;
  }

  const { token, alias } = location.search
    ? queryString.parse(location.search)
    : {};

  if (!alias || !token) {
    navigate('/');
    return null;
  }

  return (
    <Layout>
      <SEO title="Disable Alias" />
      <LogOnMount eventType="disable alias" />
      <Disable alias={alias} token={token} />
    </Layout>
  );
};

export default DisablePage;
